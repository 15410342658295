import React, { useEffect, useState } from "react";
import axios from "axios";
import MicRecorder from "mic-recorder-to-mp3";
import { cmFluencyTestStartRecordingAnalytics } from "../../utils/AnalyticsHelper";
import "antd/dist/antd.css";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as cmfluencyAction from "../../store/cmfluency/actions";
import micListening from "../../assets/image/mic_listening.gif";
import SpeechRateModal from "../../components/CMSpeechRateModal/index";
import { storage as LocalStorage } from "services/config/storage";

import "./Passage.scss";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
let mediaRecorder;
let stream;
let timeoutIds = null;

const SpeechTestPassage = (props) => {
  let paramUrl = new URLSearchParams(props.location.search);
  let passageId = paramUrl.get("passageid");
  let passage_id = paramUrl.get("passage_id"); //For CEECT

  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, seIsBlocked] = useState(false);
  const [Show_audio_msg, setShow_audio_msg] = useState(false);
  const [reRecordClicked, setIsReRecordClicked] = useState(false);

  let blobs = [];

  useEffect(() => {
    if (props.shouldPassageUpdate == undefined || props.shouldPassageUpdate) {
      if (passageId) {
        props.getPassage(`fluencypassages_by_id?passage_id=${passageId}`);
      } else if (!passageId && LocalStorage.fetch.authToken()) {
        props.getPassage(
          `fluencypassages?grade=${
            LocalStorage.fetch.defaultChild()?.grade?.name
          }`
        );
      } else {
        if (props.cmfluencyGrade) {
          props.getPassage(`fluencypassages?grade=${props.cmfluencyGrade}`);
        } else if (props.passage_id) {
          props.getPassage(
            `fluencypassages?passage_id=${props.passage_id}`
          );
        } else {
          props.history.push(`/speech-test`);
        }
      }
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        console.log("Permission Granted");
        seIsBlocked(false);
      })
      .catch(() => {
        console.log("Permission Denied");
        seIsBlocked(true);
      });
  }, []);

  useEffect(() => {}, [props.cmfluencyAudioUrl]);

  const Try_Again_audio = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        console.log("Permission Granted");
        seIsBlocked(false);
        setShow_audio_msg(false);
        // this.setState({ isBlocked: false,Show_audio_msg:false });
        // this.captureAnswer();
        startRecording();
      })
      .catch(() => {
        console.log("Permission Denied");
        seIsBlocked(true);
      });
  };

  const removeAudio = () => {
    // setBlobURL("");
    props.setAudioUrl("");
  };

  const doPreview = () => {
    if (!blobs.length) return;
    // Let's concatenate blobs to preview the recorded content
    const blobURL = URL.createObjectURL(
      new Blob(blobs, { type: mediaRecorder.mimeType })
    );
    // setBlobURL(blobURL);
    props.setAudioUrl(blobURL);
    setIsRecording(false);
  };

  const startRecording = async () => {
    clearTimeout(timeoutIds);
    timeoutIds = null;
    if (!isBlocked) {
      cmFluencyTestStartRecordingAnalytics(props.cmFluencyViewID);
      props.setAudioUrl("");
      setIsRecording(true);
      blobs = [];
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = (event) => {
        // Let's append blobs for now, we could also upload them to the network.
        if (event.data) blobs.push(event.data);
      };
      mediaRecorder.onstop = doPreview;
      // Let's receive 1 second blobs
      mediaRecorder.start(1000);

      const timeoutId = setTimeout(function () {
        endRecording();
      }, 70000);
      timeoutIds = timeoutId;
    } else {
      setShow_audio_msg(true);
    }
  };

  const endRecording = () => {
    clearTimeout(timeoutIds);
    timeoutIds = null;
    // Let's stop capture and recording
    mediaRecorder.stop();
    stream.getTracks().forEach((track) => track.stop());
    // doPreview();
  };

  return (
    <div className="cm_card_passage">
      <div className="cm_para1_note">
        <strong>Note:</strong>
        &nbsp;{" "}
        <small>
          {" "}
          When you are ready to read. Click on the 'Record' button and start
          reading. After reading the whole passage click on the 'Stop' to stop
          the recording.
          <strong>
            {" "}
            Please listen to the recorded audio to ensure it is loud and clear
            before proceeding with submission.
          </strong>
          The recording should be of 70 sec or less. The recorded audio will be
          displayed below the passage.
        </small>
      </div>
      <div className="cm_para1_title">Read the following passage</div>
      <div className="cm_passage_controls pl01">
        <button
          disabled={isRecording}
          onClick={startRecording}
          className="cm_passage_buttons left-button"
        >
          Record
        </button>
        <button
          disabled={!isRecording}
          onClick={endRecording}
          className="cm_passage_buttons right-button"
        >
          Stop
        </button>
      </div>
      <div
        className="mic-listening"
        style={{ display: isRecording ? "block" : "none" }}
      >
        <img className="mic-listening-gif" alt="mic logo" src={micListening} />
        <span className="recording-text">Recording now</span>
      </div>
      <div className="cm_para1 h01">
        {" "}
        <p>{props.cmfluencyPassage} </p>
      </div>
      {props.cmfluencyAudioUrl && (
        <div className="recorded-audio">
          <div className="audio_block">
            <audio
              src={props.cmfluencyAudioUrl}
              controls="controls"
              className="audio_element"
            />
          </div>
          <div
            onClick={() => {
              removeAudio();
            }}
          >
            X
          </div>
        </div>
      )}
      {props.cmfluencyAudioUrl == null && props.cmfluencyAudioUrl == null && (
        <span style={{ color: "red", fontSize: 14 }}>
          {isRecording
            ? "Please stop recording and then submit"
            : (() => {
                try {
                  const errorObj = JSON.parse(props.audioError);
                  //return false if errorObj.Message is 'API timeout'
                  if (errorObj.errorMessage === "API timeout")
                    return "Please record again within 70-80 secs";

                  return errorObj.errorMessage
                    ? errorObj.errorMessage + ". Please record again"
                    : errorObj;
                } catch (error) {
                  return props.audioError + ". Please record again";
                }
              })()}
        </span>
      )}
      <div
        id="Show_audio_msg"
        className="modal"
        style={{ display: Show_audio_msg ? "" : "none" }}
      >
        <div className="modal-content">
          {/* <img src={Close_Sign} onClick={()=>{this.setState({show_form:false})}} className="close-modal"/> */}
          <h2>
            It Looks like you have blocked permission to access microphone.
            Kindly unblock it and Click Try Again.
          </h2>
          <button
            className="modal-submit"
            onClick={() => {
              Try_Again_audio();
            }}
          >
            Try Again
          </button>
        </div>
      </div>

      <SpeechRateModal
        removeAudio={removeAudio}
        reRecordClicked={reRecordClicked}
        setIsReRecordClicked={setIsReRecordClicked}
      />
    </div>
  );
};
export default connect(
  ({ cmfluency, user }) => ({
    ...cmfluency,
    user: { ...user },
  }),
  (dispatch) => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(SpeechTestPassage));
