import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as storyActions from "store/story/actions";
import { Button } from "antd";
import * as EVENT from "../../utils/eventKeys";
import { Analytics } from "../../services/analytics";
import { storage as LocalStorage } from "services/config/storage";
import { v4 as uuidv4 } from "uuid";
import Quiz from "components/Quiz";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {Mixpanel} from '../../Mixpanel'
// import '../Modal.css';

function Flipbook(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageScale, setPageScale] = useState(0.8);
  const [width, setWidth] = useState(600);
  const [storyData, setStoryData] = useState({});
  const [state, setState] = useState({
    quizOpened: false,
    continueQuiz: false,
    mediaOpen: false,
    isLiked: false,
    viewId: uuidv4(),
    total_time: 0,
  });

  useEffect(() => {
    setStoryData(props.dataStory);
  }, []);

  const bookRef = useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setTotalPages(numPages);
  }
  function pagesList() {
    var pages = [];
    for (var i = 1; i <= numPages; i++) {
      pages.push(
        <div className="pdf_page">
          <Page
            key={i}
            scale={pageScale}
            width={width}
            height={560}
            pageNumber={i}
          />
        </div>
      );
    }
    return pages;
  }

  function handleZoomIn() {
    if (pageScale < 3.0) {
      // setIsTurning(true);
      const doc = document.querySelector('.react-pdf__Page canvas')
      console.log(doc)
      doc.style.minWidth =`600px`
      setPageScale(pageScale + 0.2);
      setWidth(width+20)
      // setTimeout(() => {
      // //   setIsTurning(false);
      // }, 500);
    }
  }

  function handleZoomOut() {
    if (pageScale > 0.75) {
      // setIsTurning(true);
      setPageScale(pageScale - 0.2);
      // setTimeout(() => {
      // //   setIsTurning(false);
      // }, 500);
    }
  }

  function handleNext() {
    if (pageNumber < totalPages) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (window.innerWidth > 1040) {
        if (totalPages - pageNumber == 1) {
          setPageNumber(pageNumber + 1);
        } else {
          setPageNumber(pageNumber + 2);
        }
      } else {
        setPageNumber(pageNumber + 1);
      }
      //  setDirection("left");
      setPageScale(0.95);
      //  setTimeout(() => {
      //  }, 500);
      if (bookRef.current.pageFlip().getCurrentPageIndex() < totalPages - 1) {
        // console.log(bookRef.current.pageFlip().getCurrentPageIndex());
        bookRef.current.pageFlip().flipNext();
      }
    }
  }
  function handlePrevious() {
    // console.log("inside prev", pageNumber);
    if (pageNumber > 0) {
      if (window.innerWidth > 1040) {
        if (totalPages == pageNumber) {
          setPageNumber(pageNumber - 1);
        } else {
          setPageNumber(pageNumber - 2);
        }
      } else {
        setPageNumber(pageNumber - 1);
      }

      setPageScale(0.95);

      if (bookRef.current.pageFlip().getCurrentPageIndex() > 0) {
        bookRef.current.pageFlip().flipPrev();
      }
    }
  }

  const sendStoryAnalytics = (event, dataStory, isLiked = null) => {
    let payload = {};
    payload[EVENT.STORY_TYPE] = dataStory?.story_type;
    payload[EVENT.STORY_NAME] = dataStory?.name;
    payload[EVENT.STORY_ID] = dataStory?.id;
    payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data; // create seesion id after login  UUID.randomUUID().toString()
    payload[EVENT.SOURCE_PAGE_ID] = props.source; // event source key
    payload[EVENT.PARTNER_SOURCE] = dataStory?.source; // source in dataStory response
    payload[EVENT.TIMESTAMP] = new Date();
    payload[EVENT.VIEW_ID] = state.viewId; // create a uuid for unique story
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    if (isLiked != null) {
      payload[EVENT.STORY_LIKE] = state.isLiked;
    } else {
      payload[EVENT.SECTION_ID] = dataStory?.section_data[0]?.id;
      payload[EVENT.SKILL_TAGS] =
        dataStory?.skill_tag && dataStory?.skill_tags.length > 0
          ? JSON.stringify(dataStory.skill_tags)
          : undefined;
    }
    Analytics.logEvents(event, payload);
  };

  const finishQuiz = async () => {
    // Mixpanel.track("Finish Reading", props.dataStory);

    sendStoryAnalytics(EVENT.MARK_COMPLETE_STORY, storyData);
    const { dataStory } = props;
    if (dataStory.is_quiz_taken || dataStory?.status ==='completed') {
      props.closeModal();
      return;
    }
    let res = await props.finishStoryRequest(storyData.id);
    if (res) {
      await props.closeModal();
      if (dataStory.has_mcq) {
        // setState({ quizOpened: true });
        // Mixpanel.track("QA-After Finish Story", this.props.dataStory);
        props.openQuiz()
      } else if(dataStory?.status !=='completed') {
        props.updateDetailedStory(res.status);
        setStoryData((prevState) => ({
          storyData: {
            ...prevState.storyData,
            status: res.status,
          },
        }));
        let streakPayload = {
          action_id: storyData.id,
          action_type: "story",
        };
        // call the createStreakRequest to update the streak
        const { result } = await props.createStreakRequest(streakPayload);

        if (!result.IsUpdated) {
          // append streak to the dataStory variable
          res.streak = 1;
        }
        console.log("no MCQ story");
        props.openSuccessModal(
          res.result ? res.result : res.data ? res.data : res
        );
      }else{
         props.closeModal();
         return;
      }
    }
  };

  const lastQuestionAnswered = async (dataStory) => {
    setState({ quizOpened: false });
    console.log("datastory:", dataStory);
    let streakPayload = {
      action_id: storyData.id,
      action_type: "story",
    };
    // call the await createStreakRequest to update the streak
    const { result } = await props.createStreakRequest(streakPayload);
    if (!result.IsUpdated) {
      // append streak to the dataStory variable
      dataStory.streak = 1;
    }
    props.openSuccessModal(dataStory);
  };

  return (
    <>
      {window.innerWidth > 650 ? (
        <div className="pdf_page">
          <button
            className="pdf_control_btn"
            onClick={handlePrevious}
            disabled={pageNumber == 1}
          >
            <FaChevronLeft />
          </button>
          <div className="pdf_main_container">
            <Document
              file={props.url}
              onLoadSuccess={onDocumentLoadSuccess}
              className="modal-90w"
            >
              <HTMLFlipBook
                width={width}
                height={560}
                showCover={true}
                useMouseEvents={false}
                ref={bookRef}
                // usePortrait={false}
                // size='stretch'
              >
                {pagesList()}
              </HTMLFlipBook>
            </Document>

            <div className="pdf-toolbar">
              <div className="button-container">
                <button
                  className="pdf_control_btn"
                  onClick={handleZoomIn}
                  disabled={pageScale >= 3}
                >
                  +
                </button>
              </div>
              <div className="pdf-page-count">
                Page {pageNumber} of {totalPages}
              </div>
              <div className="button-container">
                <button
                  className="pdf_control_btn"
                  onClick={handleZoomOut}
                  disabled={pageScale <= 0.75}
                >
                  -
                </button>
              </div>
            </div>
          </div>
          {pageNumber !== totalPages ? (
            <button
              className="pdf_control_btn"
              onClick={handleNext}
              disabled={pageNumber === totalPages}
            >
              <FaChevronRight />
            </button>
          ) : (
            <div></div>
          )}
          {!props.activityPage && pageNumber === totalPages && (
            <Button
              type="primary"
              className="primary-btn ml-16 finish_btn"
              size="large"
              //style={{ marginLeft: "16px" }}
              onClick={finishQuiz}
            >
              Finish
            </Button>
          )}
        </div>
      ) : (
        <div className="pdf_page">
          <div className="pdf_main_container">
            <Document
              file={props.url}
              onLoadSuccess={onDocumentLoadSuccess}
              className="modal-90w"
            >
              <HTMLFlipBook
                width={400}
                height={530}
                showCover={true}
                useMouseEvents={false}
                ref={bookRef}
              >
                {pagesList()}
              </HTMLFlipBook>
            </Document>

            <div className="pdf-toolbar">
              <div className="button-container">
                <button
                  className="pdf_control_btn"
                  onClick={handlePrevious}
                  disabled={pageNumber == 1}
                >
                  &lt;
                </button>
                <button
                  className="pdf_control_btn"
                  onClick={handleZoomIn}
                  disabled={pageScale >= 3}
                >
                  +
                </button>
              </div>
              <div className="pdf-page-count">
                Page {pageNumber} of {totalPages}
              </div>
              <div className="button-container">
                <button
                  className="pdf_control_btn"
                  onClick={handleZoomOut}
                  disabled={pageScale <= 0.75}
                >
                  -
                </button>

                {pageNumber === totalPages && !props.activityPage ? (
                  <Button
                    type="primary"
                    className="primary-btn finish_btn_small"
                    size="large"
                    style={{ margin: "0px !important" }}
                    onClick={finishQuiz}
                  >
                    Finish
                  </Button>
                ) : (
                  <button
                    className="pdf_control_btn"
                    onClick={handleNext}
                    disabled={pageNumber === totalPages}
                  >
                    ›
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {state.quizOpened && (
        <div className="quiz_container">
          <Quiz
            viewId={state.viewId}
            source={props.source}
            questions={props.questions}
            finalAnswer={lastQuestionAnswered}
            closeQuiz={() => setState({ quizOpened: false })}
            storyData={storyData}
            isStory={true}
          />
        </div>
      )}
    </>
  );
}
export default connect(
  ({ detailedStory }) => ({ ...detailedStory }),
  (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(Flipbook);
