import { Api } from "./config/request";

export const getQuizList = () =>
  Api.getExternalRequest(
    "https://moo.getfreadom.com/webservice/rest/server.php?moodlewsrestformat=json&courseids[0]=3&wsfunction=mod_quiz_get_quizzes_by_courses&wstoken=ce61852f23de631dc697daa2735c5210"
  );
export const getAllQuiz = (params) =>
  Api.getRequest(`quiz/v1/?grade_id=${params}`);
export const getQuizByCategory = (gradeId) =>
  Api.getRequest(`quiz/v1/list_by_category/?grade_id=${gradeId}`);
export const getQuizStatus = (quizId, childId) =>
  Api.getRequest(`quiz/v1/completed/?quiz_id=${quizId}&child_id=${childId}`);
export const getAllLeadQuiz = (params) =>
  Api.getFliveRequest(`quiz/grade/${params}`, true);
export const getQuizContent = (params) => Api.getRequest(`quiz/v1/${params}/`);
export const getById = (params) => Api.getRequest(`quiz/v1/by_id/${params}`);
export const getLeadQuizContent = (params) =>
  Api.getFliveRequest(`quiz/${params}`, true);
export const getLeadQuiz = (params) =>
  Api.getFliveRequest(`quiz/get_by_id/${params}`, true);
export const storeQuizResult = (payload) =>
  Api.postRequest(`quiz/v1/result/`, payload);
  export const storeSingleQuizResult = (payload) =>
  Api.postRequest(`quiz/v1/quiz_response/`, payload);

export const storeStudentInfo = (payload) =>
  Api.postFliveRequest(`quiz/quiz_user`, payload, true);
export const storeLeadQuizResult = (payload) =>
  Api.postFliveRequest(`quiz/lead_gen_quiz_result`, payload, true);

export const createStreak = (payload) => Api.postRequest('user/v1/streak/', payload);

export const QuizApiCalls = {
  getQuizList,
  getAllQuiz,
  getQuizByCategory,
  getQuizContent,
  getLeadQuizContent,
  storeQuizResult,
  storeSingleQuizResult,
  storeStudentInfo,
  storeLeadQuizResult,
  getAllLeadQuiz,
  getQuizStatus,
  getById,
  getLeadQuiz,
  createStreak,
};
