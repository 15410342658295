import { Modal } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as cmfluencyAction from "../../store/cmfluency/actions";
import "./index.scss";

const SpeechRateModal = ({
  isSpeechRateModal,
  handleCMCongratulationModal,
  handleSpeechRateModal,
  removeAudio,
  speechRate,
  setIsReRecordClicked,
  handleshouldPassageUpdate,
  setRocketLoader,
}) => {
  return (
    <Modal
      className="speechrate_modal"
      centered
      visible={isSpeechRateModal}
      onCancel={() => handleSpeechRateModal(false)}
      maskClosable={false}
      closable={true}
      footer={null}
    >
      <div className="modal-content">
        {speechRate && speechRate === "Slow" ? (
          <h6>
            Your reading speed was quite slow!
            <br /> We suggest reading at a normal pace. Would you like to
            re-record or submit your test?
          </h6>
        ) : speechRate && speechRate === "Fast" ? (
          <h6>
            Your reading speed was quite fast!
            <br /> We suggest reading at a normal pace. Would you like to
            re-record or submit your test?
          </h6>
        ) : (
          null
        )}
        <button
          className="modal-submit"
          onClick={() => {
            handleSpeechRateModal(false);
            removeAudio();
            setIsReRecordClicked(true);
          }}
        >
          Re-Record
        </button>

        <button
          className="modal-submit"
          onClick={() => {
            handleSpeechRateModal(false);
            setRocketLoader(true);
            setTimeout(() => {
              setRocketLoader(false)
              handleshouldPassageUpdate(true);
              handleCMCongratulationModal(true);
            }, 2500);

          }}
        >
          SUBMIT TEST
        </button>
      </div>
    </Modal>
  );
};

export default connect(
  ({ cmfluency }) => ({
    ...cmfluency,
  }),
  (dispatch) => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(SpeechRateModal));
