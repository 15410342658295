import React from "react";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as cmfluencyAction from "../../store/cmfluency/actions";
import muteSVG from "../../assets/image/mute.svg";
import readSVG from "../../assets/image/read.svg";
import pointSVG from "../../assets/image/point.svg";
import submitSVG from "../../assets/image/submit.svg";
import { storage as LocalStorage } from "services/config/storage";
import "./Content.scss";

const Content = (props) => {
  let paramUrl = new URLSearchParams(props.location.search);
  let passageId = paramUrl.get("passageid");
  let testid = paramUrl.get("testid");
  let isLogin = LocalStorage.fetch.authToken() ? true : false;

  const handleStartInstructions = () => {
    props.setAudioUrl("");
    if (passageId) {
      props.history.push(
        `/speech-test/passage?passageid=${passageId}&testid=${testid}`
      );
    } else if (!passageId && LocalStorage.fetch.authToken()) {
      props.history.push(`/speech-test/passage`);
    } else if (props.ceectTest) {
      props.history.push(
        "/ceect/speech-test/passage/?passage_id=da8361d4-8fdc-4861-8146-6526676bff88"
      );
    } else {
      props.history.push("/speech-test/passage");
    }
  };
  return (
    <div
      id="test_instructions"
      className={`text-instructions-layout ${isLogin ? "on-login" : null}`}
    >
      <div className="cm_instructions_container">
        <div className="cm_title_container">
          <div className="cm_para1_title">Test Instructions</div>
          <div className="cm_para1 pb01">
            Welcome to the {props.ceectTest?"Fluency Assessment Test":"Freadom Fluency Test"}. {props.ceectTest?"Teachers":"Parents/Teachers"} are requested
            to follow these instructions:
          </div>
        </div>
        <div className="cm_card_instruction">
          <div className="cm_left_div_block">
            <img
              src={muteSVG}
              loading="lazy"
              width="100"
              height="100"
              alt=""
              className="cm_instructions_icon"
            />
          </div>
          <div className="cm_right_div_block">
            <div className="cm_para1">
              Sit in a quiet place with no background noise.
            </div>
          </div>
        </div>
        <div className="cm_card_instruction">
          <div className="cm_left_div_block">
            <img
              src={readSVG}
              loading="lazy"
              width="100"
              height="100"
              alt=""
              className="cm_instructions_icon"
            />
          </div>
          <div className="cm_right_div_block">
            <div className="cm_para1">
              {
                props.ceectTest?
                "Read the following passage aloud"
                :
              "Ask your child to read the following passage aloud."
              }
            </div>
          </div>
        </div>
        <div className="cm_card_instruction">
          <div className="cm_left_div_block">
            <img
              src={pointSVG}
              loading="lazy"
              width="100"
              height="100"
              alt=""
              className="cm_instructions_icon"
            />
          </div>
          <div className="cm_right_div_block">
            <div className="cm_para1">
             {props.ceectTest?"Please read as per your comfort and ability.":'Don&#x27;t prompt the child. Let them read as per their comfort and ability.'}
            </div>
          </div>
        </div>
        <div className="cm_card_instruction">
          <div className="cm_left_div_block">
            <img
              src={muteSVG}
              loading="lazy"
              width="100"
              height="100"
              alt=""
              className="cm_instructions_icon"
            />
          </div>
          <div className="cm_right_div_block">
            <div className="cm_para1">
              Listen to the recording once {props.ceectTest?"you have read the passage":"the child is done"}. If there are
              disturbances, please re-record.
            </div>
          </div>
        </div>
        <div className="cm_card_instruction">
          <div className="cm_left_div_block">
            <img
              src={submitSVG}
              loading="lazy"
              width="100"
              height="100"
              alt=""
              className="cm_instructions_icon"
            />
          </div>
          <div className="cm_right_div_block">
            <div className="cm_para1">
              Select the best recording and submit the form.
            </div>
          </div>
        </div>
        <div className="div-block-40">
          <a
            id="freadom1_link"
            onClick={() => {
              handleStartInstructions();
            }}
            className="cm_section_button"
          >
            Start
          </a>
        </div>
      </div>
    </div>
  );
};
export default connect(
  ({ cmfluency }) => ({
    ...cmfluency,
  }),
  (dispatch) => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(Content));
