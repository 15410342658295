import axios from "axios";
export const speechTestLogs = async (payload) => {
    const response = await axios.post(
      'https://script.google.com/macros/s/AKfycbzmdGpDhmL-P1f3YSILeWy9qigU9AUBB4cNdWGceR43uW2eYpOLQD2gWm-76HHjSw8g/exec',
      payload,
      {
        headers: {
          'Content-Type': 'text/plain', // Use 'application/json' for JSON payloads
        },
        mode: "no-cors"
      }
    );
    return response;
  };
export const SpreadSheetLogsAPIcalls = {
    speechTestLogs
};