import React, { Component } from "react";
import "./Header.scss";
import Freadom from 'assets/image/freadom-logo.svg'
import { Button } from "antd";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="header" id="report-header">
        <img src={Freadom} alt="logo" />
        {
          !this.props.ceectTest &&
        <Button
        type="primary"
        size="default"
        onClick={() =>
          window.open("https://go.getfreadom.com/more/freadom_app/", "_blank")
        }
        >
          GET THE APP
        </Button>
        }
      </div>
    );
  }
}

export default Header;
