import React, { useState } from "react";
import { Progress } from "antd";
import { connect } from "react-redux";
import {  withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import * as cmfluencyAction from "../../store/cmfluency/actions";
import "antd/dist/antd.css";
import './CMFluencyLoader.scss';
import { storage as LocalStorage } from "services/config/storage";
import rocketsvg from '../../assets/image/rocket-launch.svg';
// const validateMessages = {
//   required: "${label} is required!",
//   types: {
//     email: "${label} is not a valid email!",
//     number: "${label} is not a valid number!",
//   },
// };

const CMFluencyLoader = (props) => {
  let isLogin = LocalStorage.fetch.authToken() ? true : false;


  return (
    <div className="loader-layout">
      <div className="layout-wrapper">
        <div className="rocket"></div>
        {isLogin ? (
          <span className="loadin-text">LOADING...</span>
        ) : (
          <span className="loadin-text">PLEASE WAIT</span>
        )}
        {isLogin ? (
          <span className="minute-text">
            Generating your{" "}
            {props.ceectTest
              ? "Fluency Assessment Test "
              : "Freadom Speech Test "}
            Report
          </span>
        ) : (
          <span className="minute-text">
            Your{" "}
            {props.ceectTest
              ? "Fluency Assessment Test "
              : "Freadom Speech Test "}
            is getting submitted
          </span>
        )}
      </div>
    </div>
  );
};

export default connect(
  ({ cmfluency }) => ({
    ...cmfluency
  }),
  dispatch => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(CMFluencyLoader));
