import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import { FaChevronLeft, FaChevronRight, FaMicrophoneSlash } from "react-icons/fa";
import ePub from "epubjs";
import Loader from "assets/image/loader.gif";
import micListeningGif from "assets/image/mic_listening.gif";
import { storage as LocalStorage } from "services/config/storage";
import Navbar from "../../components/Navbar";
import _debounce from "lodash/debounce";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import { FaMicrophone } from "react-icons/fa";
import { BiSolidMicrophone } from "react-icons/bi";
import { API_URL } from "../../services/config/apiUrl";

const StoryReadAloud = ({ data }) => {
    const [storyData, setStoryData] = useState({});
    const [epub_rendition, updateRendition] = useState({});
    const [showStartButton, setShowStartButton] = useState(false);
    const [showTryAgainButton, setShowTryAgainButton] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [session_id, setSessionId] = useState("");
    const [process_id, setProcessId] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [error, setError] = useState(null);
    const [showErrorDiv, setShowErrorDiv] = useState(true);
    const [showTextHighlight, setShowTextHighlight] = useState(true);
    const [showPrevButton, setShowPrevButton] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [stopRecButton, setStopRecButton] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [count, setcount] = useState(0);
    const [showFinishButton, setShowFinishButton] = useState(false);
    const [isReadAloud, setIsReadAloud] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [playbackRate, setPlayBackRate] = useState(1);
    const [selections, setSelections] = useState([]);
    const [currentWordIndex, setCurrentWordIndex] = useState(1);
    const [currentParaIndex, setCurrentParaIndex] = useState(1);
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState('');

    const viewerRef = useRef(null);
    const renditionRef = useRef(null);
    const utteranceRef = useRef(null);
    const textNodesRef = useRef([]);

    const speechSynthesisRef = window.speechSynthesis
    const speechSynthesis = window.speechSynthesis;

    var fin = 0;

    useEffect(() => {
        setStoryData(data);
        loadAndDisplayEPUB();
    }, []);

    const updatePlaybackRate = (e) => {
        const newPlaybackRate = parseFloat(e.target.value);
        setPlayBackRate(newPlaybackRate);
    };

    const cleanText = (text) => {
        return text.replace(/[^a-zA-Z0-9\s]/g, ""); // Removing all non-alphanumeric characters
    };

    useEffect(() => {
        if (renditionRef.current) {
            const debouncedSetRenderSelection = _debounce(
                async (cfiRange, contents) => {
                    // console.log(cfiRange)
                    if (renditionRef.current) {
                        const cleanedText = cleanText(
                            renditionRef.current.getRange(cfiRange).toString()
                        );
                        setSelections({
                            text: cleanedText,
                            cfiRange,
                        });
                        renditionRef.current.annotations.remove(selections.cfiRange, "highlight");

                        renditionRef.current.annotations.add(
                            "highlight",
                            cfiRange,
                            {},
                            (e) => console.log("click on selection", cfiRange, e),
                            "hl",
                            {
                                fill: "#c899eb",
                                "fill-opacity": "0.5",
                                "mix-blend-mode": "multiply",
                            }
                        );
                        const selection = contents.window.getSelection();
                        // console.log(selection);
                        selection.removeAllRanges();
                    }
                },
                500
            );
            renditionRef.current.on("selected", debouncedSetRenderSelection);
            return () => {
                renditionRef.current.off("selected", debouncedSetRenderSelection);
            };
        }
    }, [setSelections, selections]);

    useEffect(() => {
        readAloud(selections?.text);
    }, [selections]);

    const recordAudio = () =>
        new Promise(async (resolve) => {
            // console.log("inside rec promise",navigator.mediaDevices);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // console.log("stream", stream);
            //   setStream(stream);
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks = [];
            // console.log(stream);
            // console.log(mediaRecorder);

            mediaRecorder.addEventListener("dataavailable", (event) => {
                audioChunks.push(event.data);
            });

            const start = () => {
                mediaRecorder.start();
                // setRecording(true);
            };

            const stop = () =>
                new Promise((resolve) => {
                    // console.log("called stop method");
                    mediaRecorder.addEventListener("stop", () => {
                        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
                        const audioUrl = URL.createObjectURL(audioBlob);
                        const audio = new Audio(audioUrl);
                        const play = () => audio.play();
                        // setRecording(false);
                        // setAudioPlayer({ audioBlob, audioUrl, play });
                        resolve({ audioBlob, audioUrl, play });
                    });
                    // console.log("media recorder state", mediaRecorder.state);
                    if (mediaRecorder.state !== "inactive") {
                        mediaRecorder.stop();
                    }
                });

            resolve({ start, stop });
        });

    const readAloud = (text) => {
        const utterance = new SpeechSynthesisUtterance(text);
        const voice = voices.find(voice => voice.name === selectedVoice);
        // console.log(voice)
        if (voice) {
            utterance.voice = voice;
        }
        utterance.lang = 'en-IN';
        utterance.rate = 0.65;
        utterance.pitch = 1;
        speechSynthesis.speak(utterance);
    };

    const loadAndDisplayEPUB = () => {
        const book = ePub(data?.url);

        let renditionWidth = 600;

        // Set default height for rendition
        let renditionHeight = 600;

        // Check if the screen width is less than 600
        if (window.innerWidth < 600) {
            renditionWidth = window.innerWidth - 100;
        }

        // Check if the screen height is less than a certain threshold (e.g., 700)
        if (window.innerHeight < 800 && window.innerHeight > 667) {
            renditionHeight = window.innerHeight - 110;
        }

        if (window.innerHeight <= 667) {
            renditionHeight = window.innerHeight - 250;
        }

        const rendition = book.renderTo("epub-file", {
            width: renditionWidth,
            height: renditionHeight,
        });

        renditionRef.current = rendition;

        updateRendition(rendition);

        setShowLoading(true);
        setShowStartButton(false);
        setShowNextButton(false);
        setShowPrevButton(false);

        rendition.display().then(() => {
            // console.log("EPUB rendering complete");
            setShowLoading(false);
            // setShowStartButton(true);
            setShowNextButton(true);
            setShowPrevButton(false);
        });

        rendition.on("rendered", () => {
            setTextNodes()
            loadVoices()
            const screenWidth = window.innerWidth;
            var iframe = document.querySelector("iframe");
            var iframeDocument =
                iframe.contentDocument || iframe.contentWindow.document;

            // Find the image element within the iframe content
            let body = iframeDocument.querySelector("body");
            let para = iframeDocument.querySelector("p");
            var image = iframeDocument.querySelector(".image img");
            var image_container = iframeDocument.querySelector(".image");

            // Check if the screen width is greater than a certain threshold (e.g., 768px)
            if (screenWidth > 768) {
                // Apply styles to the image
                if (image) {
                    image.style.width = "300px";
                    image.style.height = "300px";
                }
                if (body) {
                    body.style.display = "flex";
                    body.style.flexDirection = "column";
                    body.style.justifyContent = "center";
                    // body.style.gap = "8px";
                    body.style.alignItems = "center";
                }
            } else {
                if (image_container) {
                    image_container.style.width = "200px";
                    image_container.style.height = "200px";
                }
                if (image) {
                    image.style.width = "100%";
                    // image_container.style.height = "300px";
                }

                if (body) {
                    body.style.width = "fit-content";
                    body.style.display = "flex";
                    body.style.flexDirection = "column";
                    body.style.alignItems = "center";
                }
                if (para) {
                    para.style.width = "100%";
                }
            }
        });

        const modalContent = document.querySelector(".modal-content");
        modalContent.classList.add("epub-content");
    };

    const prevPage = async () => {
        if (fin == count) {
            setShowFinishButton(false);
        }
        setcount(count - 1);
        speechSynthesisRef.cancel()
        setIsReadAloud(false)

        // console.log(count);

        if (count === 0) {
            setShowPrevButton(false);
            setShowNextButton(true);
            return;
        }


        if (epub_rendition.location.start.index == 4) {
            // console.log("clicked on prevPage 4");
            setShowStartButton(false);
            await epub_rendition.display(0);
            epub_rendition.display(0).then(async () => {
                updateRendition(epub_rendition);
                setShowPrevButton(false);
                //  await LoadAudio();
            });
        } else {
            // await epub_rendition.prev();
            let prevDone = new Promise(async (res, reject) => {
                res(await epub_rendition.prev());
            });

            prevDone.then(async (res) => {
                console.log("index:", epub_rendition?.location?.start?.index);


                updateRendition(epub_rendition);
            });
            setShowFinishButton(false);
        }

        // Check if the current page index is the first index
        if (epub_rendition.location.atStart) {
            setShowPrevButton(false);
            setShowNextButton(true);
        } else {
            setShowPrevButton(true);
            setShowNextButton(true);
        }

        var highlightText = document.getElementById("textHighlight");
        if (highlightText) {
            highlightText.innerHTML = "?";
            highlightText.style.display = "none";
        }
    };

    function getRandomID() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    const getShownText = () => {
        // console.log("get shown func");
        var range = epub_rendition.getRange(
            epub_rendition.currentLocation().start.cfi
        );
        var endRange = epub_rendition.getRange(
            epub_rendition.currentLocation().end.cfi
        );
        // console.log(range);
        // console.log(endRange);
        range.setEnd(endRange.startContainer, endRange.startOffset);
        let targetPhrase = range.toString();
        // setTargetPhrase(targetPhrase);
        // console.log(targetPhrase);
    };

    const stopRecording = async () => {
        // console.log("reached function stop rec");
        var mic_listening = document.getElementById("mic_listening");
        mic_listening.style.display = "none";
        setShowStartButton(true);
        setStopRecButton(false);
        setShowLoading(true);
        setShowStartButton(false);
        setShowPrevButton(false);
        setShowNextButton(false);
        try {
            // console.log("try");
            const audio = await recorder.stop();
            // console.log("audio:", audio);

            var wavfromblob = new File([audio.audioBlob], "incomingaudioclip.wav");
            var audioFormdata = new FormData();
            audioFormdata.append("file", wavfromblob, "New Recording.wav");

            const response = await fetch(
                `${API_URL}misc/v1/media-v2/`,
                {
                    method: "post",
                    body: audioFormdata,
                }
            );

            if (!response.ok) {
                // Handle HTTP errors (e.g., 4xx, 5xx)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const text = await response.json();
            // var token = LocalStorage.fetch.authToken();
            // console.log("Token", token);

            const fluencyResponse = await fetch(
                `${API_URL}misc/v1/reading-fluency-iitb/`,
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        // authorization: `JWT ${token}`,
                    },
                    body: JSON.stringify({
                        audio_path: text?.result?.file,
                        book_title: storyData.name,
                        process_id: process_id,
                        session_id: session_id,
                        grade: LocalStorage.fetch.defaultChild()?.grade ? LocalStorage.fetch.defaultChild()?.grade?.name:'Test POC',
                        page: epub_rendition.location.start.index - 3,
                    }),
                }
            );

            if (!fluencyResponse.ok) {
                // Handle API errors
                const fluencyError = await fluencyResponse.json();
                const errorMessage =
                    fluencyError?.ExceptionMessage ||
                    "Internal Server Error. Contact Administrator.";

                // Update loader and button states
                setShowLoading(false);
                setStopRecButton(false);
                setShowStartButton(true);
                setShowNextButton(true);
                setShowPrevButton(true);
                setError(errorMessage);

                // Display error message to the user with styling
                const apiResponseElement = document.getElementById("api-response-text");
                const startButton = document.getElementById("recordButton");

                if (apiResponseElement && startButton) {
                    // apiResponseElement.innerHTML = `<span style="font-weight: 600; font-size: 20px; text-align: center; color: red;margin-bottom:2px; width:100%;" className="alert-class">Please read again!</span>`;
                    startButton.innerHTML = "Start";
                } else {
                    console.error("api-response-text or recordButton element not found");
                }
            } else {
                console.log("else");
                setError(null);
                let resp = await fluencyResponse.json();
                const allPTags = document.querySelectorAll("p");

                var iframe = document.querySelector("iframe");
                var targetHtml = " ";
                var ePubTextList = iframe.contentWindow.document.querySelectorAll("p");
                // console.log("epublist", ePubTextList);
                var imagecontainer =
                    iframe.contentWindow.document.querySelectorAll("img");
                // console.log("imagecontainer", imagecontainer);
                if (imagecontainer.length > 0) {
                    imagecontainer[0].style.width = "200px";
                    imagecontainer[0].style.height = "200px";
                }
                ePubTextList.forEach(function (p) {
                    targetHtml += p.outerHTML;
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;

                var elem = ifrDoc.createElement("div");

                elem.innerHTML = resp?.result?.marked_text;
                ifrDoc.body.appendChild(elem);

                setShowLoading(false);
                setShowStartButton(true);
            }
        } catch (error) {
            console.error("Error in stopRecording:", error);

            // Update loader and button states
            setShowLoading(false);
            setStopRecButton(false);
            setShowStartButton(true);
            setError(error);

            // Display generic error message to the user with styling
            const apiResponseElement = document.getElementById("api-response-text");
            const startButton = document.getElementById("recordButton");

            if (apiResponseElement && startButton) {
                // apiResponseElement.innerHTML = `<span style="font-weight: 600; font-size: 20px; text-align: center;color: red; margin-bottom:2px;">Error in processing audio. Please try again.</span>`;
                startButton.innerHTML = "Start";
            } else {
                console.error("api-response-text or recordButton element not found");
            }
        }

        setShowNextButton(true);
        setShowPrevButton(true);
    };

    const startRecording = async () => {
        // console.log("start func");
        renditionRef.current.annotations.remove(selections.cfiRange, "highlight");
        speechSynthesisRef.cancel()
        setIsReadAloud(false)

        const apiResponseElement = document.getElementById("api-response-text");
        // console.log("API div okay!", apiResponseElement);
        apiResponseElement.innerHTML = "";

        setApiResponse(null);
        // setShowStartButton(false);
        setShowTryAgainButton(false);

        if (error) setError(false);

        var session_id = getRandomID();
        setSessionId(session_id);
        // console.log(session_id);
        var process_id = getRandomID();
        setProcessId(process_id);
        // console.log(process_id);
        var mic_listening = document.getElementById("mic_listening");

        // console.log("Mic", mic_listening);
        setShowNextButton(false);
        setShowPrevButton(false);

        // tryAgainButton.style.display = "none";
        setShowTryAgainButton(false);
        setShowErrorMessage(false);
        // errorDiv.style.display = "none";
        setShowErrorDiv(false);
        // highlightText.innerHTML = "?";
        // highlightText.style.display = "none";
        setShowTextHighlight(false);
        // stopButton.disabled = false;
        setStopRecButton(true);
        getShownText();
        mic_listening.style.display = "block";
        var recorder = await recordAudio();
        setRecorder(recorder);
        // console.log("Recorder", recorder);
        recorder.start();

    };

    const nextPage = async () => {
        // console.log("reached next page");
        speechSynthesisRef.cancel()
        setIsReadAloud(false)
        if (epub_rendition?.location?.atEnd) {
            // console.log("Already at the last page");
            setShowFinishButton(true);
            setShowNextButton(false);
            fin = count;
            // replace the current url with /read-along

            return;
        } else {
            setShowFinishButton(false);
        }
        if (fin == count) {
            setShowFinishButton(false);
        }
        setcount(count + 1);
        // console.log(count);

        if (count >= 0) {
            setShowPrevButton(true);
        }

        // Check if the current location is already at the end

        if (epub_rendition.location.start.index < 4) {
            // console.log("in thumbnail");
            setShowStartButton(true);
            await epub_rendition.display(4);
        } else {
            setShowStartButton(true);
            await epub_rendition.next()
        }

        // Check if the current page index is the last index
        if (epub_rendition.location.atEnd) {
            // console.log("reached end");
            setShowNextButton(false);
            setShowPrevButton(true);
        } else {
            // console.log("mid");
            setShowNextButton(true);
            setShowPrevButton(true);
        }
    };

    const FinishStory = async () => {
        window.location = "/read-along";
    };

    const pauseReading = () => {
        speechSynthesisRef.pause()
        setIsPaused(true)
    }

    const resumeReading = () => {
        speechSynthesisRef.resume()
        setIsPaused(false)
    }

    useEffect(() => {
        if (error !== null) {
            var iframe = document.querySelector("iframe");
            var targetHtml = " ";
            var ePubTextList = iframe.contentWindow.document.querySelectorAll("p");
            if (error) {
                ePubTextList.forEach(function (p) {
                    targetHtml += `<p style="color:red;">${p.innerText}</p>`;
                    // console.log(targetHtml);
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;
                var elem = ifrDoc.createElement("div");
                elem.innerHTML = targetHtml;
                ifrDoc.body.appendChild(elem);
            } else {
                ePubTextList.forEach(function (p) {
                    targetHtml += `<p>${p.innerText}</p>`;
                    // console.log(targetHtml);
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;
                var elem = ifrDoc.createElement("div");
                elem.innerHTML = targetHtml;
                ifrDoc.body.appendChild(elem);
            }
        }
    }, [error]);

    useEffect(() => {

        if (viewerRef.current) {
            // const doc = viewerRef.current.querySelector('iframe')?.contentWindow.document
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document?.body
            const words = doc_body?.getElementsByTagName('span');
            let currentWord = ''
            // console.log(words)
            for (let i = 0; i < words?.length; i++) {
                // console.log(words[i])
                words[i].classList.remove('highlight');
                words[i].style.background = "none"
            }
            if (currentWordIndex === 0 || currentParaIndex === 0) currentWord = doc_body?.querySelector(`#word-${currentParaIndex}${currentWordIndex + 1}`)
            else currentWord = doc_body?.querySelector(`#word-${currentParaIndex}${currentWordIndex + 1}`)
            // console.log(currentWord?.innerText, `#word-${currentParaIndex}${currentWordIndex}`)
            if (currentWord) {
                currentWord.classList.add('highlight');
                currentWord.style.background = "yellow"
            }
        }
    }, [currentWordIndex]);

    useEffect(() => {
        if (!speechSynthesisRef.speaking) {
            setIsReadAloud(false)
        }
    }, [speechSynthesisRef.speaking])

    const setTextNodes = () => {
        // console.log(viewerRef.current)
        if (viewerRef.current) {
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document

            let textNodes = getTextNodes(doc_body);
            textNodesRef.current = textNodes

        }
    }

    const getTextNodes = (doc) => {
        const extractedContent = [];
        let Alltext = ''

        // Helper function to process each element and its children recursively
        const processElement = (element, index) => {

            // Process paragraph <p> elements
            if (element.tagName.toLowerCase() === 'p') {
                const text = element.textContent.trim();
                if (text) {
                    extractedContent.push({
                        type: 'text',
                        content: text,
                        index: `text-${index}`,
                    });

                    Alltext = Alltext.concat(text)
                    Alltext = Alltext.concat(' ')
                }
            }
            // Process image <img> elements directly
            else if (element.tagName.toLowerCase() === 'img') {
                extractedContent.push({
                    type: 'image',
                    src: element.getAttribute('src'),
                    index: `image-${index}`,
                });
            }
            else if (element.tagName.toLowerCase() === 'div') {
                // Recursively process all child nodes of the current div
                Array.from(element.children).forEach((child, childIndex) => {
                    processElement(child, `${index}-${childIndex}`);
                });
            }
        };

        // Start processing the children of the body element
        Array.from(doc.body.children).forEach((child, index) => {
            processElement(child, index);
        });

        // setAllText(Alltext)
        return extractedContent;
    };

    const startReadAloud = async () => {
        setIsReadAloud(true)
        renditionRef.current.annotations.remove(selections.cfiRange, "highlight");

        const doc_body = viewerRef.current.querySelector('iframe').contentWindow.document.body

        let textHtml = `<div>`
        if (textNodesRef.current.length > 0) {
            textNodesRef.current.forEach((node, ind) => {
                // console.log(node)
                const content = node.type === 'text' ? node.content : null
                const src = node.type === 'image' ? node.src : null
                if (node.type === 'text') {
                    if (content) {
                        textHtml = textHtml.concat('<p>')
                        const words = content.split(/\s+/);
                        let wrappedText = words.map((word, index) => `<span id="word-${ind + 1}${index + 1}">${word} </span>`).join('');

                        textHtml = textHtml.concat(wrappedText)
                        textHtml = textHtml.concat('</p>')
                        utteranceRef.current = new SpeechSynthesisUtterance(content);
                        const voice = voices.find(voice => voice.name === selectedVoice);
                        // console.log(voice)
                        if (voice) {
                            utteranceRef.current.voice = voice;
                        }
                        utteranceRef.current.lang = 'en-IN';
                        utteranceRef.current.rate = 0.65;
                        utteranceRef.current.pitch = 1;
                        utteranceRef.current.onboundary = (event) => {

                            if (event.name === 'word') {
                                const charIndex = content.slice(0, event.charIndex).split(/\s+/).length - 1;
                                // console.log(charIndex,currentParaIndex,ind)
                                setCurrentWordIndex(charIndex);
                            }
                            setCurrentParaIndex(ind + 1)
                        };
                        utteranceRef.current.onend = () => {
                            setCurrentParaIndex((prev) => prev + 1)
                            setCurrentWordIndex(0);
                        };
                        speechSynthesisRef.speak(utteranceRef.current);
                        setIsReadAloud(true);
                    }
                }
                else {
                    textHtml = textHtml.concat(`<img style="width:300px;height:300px" src="${src}"/>`)
                }
            })
            textHtml = textHtml.concat('</div>')
            console.log(textHtml)
            doc_body.innerHTML = textHtml;
        }

        doc_body.style.display = "flex"
        doc_body.style.gap = '8px'

    }

    const stopReadAloud = () => {
        if (speechSynthesisRef.speaking) {
            speechSynthesisRef.cancel();
            setIsReadAloud(false)
        }
    }

    const loadVoices = () => {
        const voices = speechSynthesisRef.getVoices();
        let indianVoices = voices.filter(voice => voice.lang === 'en-IN');
        let defaultVoices = voices.filter((voice) => voice.localService === true)
        setVoices(defaultVoices);
        if (voices.length > 0) {
            if (indianVoices.length > 0) {
                let indianVoice = indianVoices[0];
                setSelectedVoice(indianVoice?.name);
        } else {
            let defaultVoice = voices[0];
            setSelectedVoice(defaultVoice?.name)
}
}
    };

    return (
        <div>
            <Navbar />
            <div className="read_along_container">
                {showPrevButton ? (
                    <div className="left-icon" id="prev" onClick={prevPage}>
                        <FaChevronLeft className="nav-icon leftnav pdf_control_btn" />
                    </div>
                ) : (
                    <div className="left-icon" />
                )}
                {/* {console.log("state", showPrevButton)} */}

                <div className="modal-content">
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#F1EEF5",
                            position: "relative",
                        }}
                    ></div>

                    <div id="epub-file" className="epubfile" ref={viewerRef}></div>
                </div>
                {showNextButton ? (
                    <div className="right-icon" id="next" onClick={nextPage}>
                        <FaChevronRight className="nav-icon rightnav pdf_control_btn" />
                    </div>
                ) : (
                    <div className="right-icon" />
                )}
            </div>

            {showErrorDiv ? (
                <div className="errorDiv">
                    <p id="error-message"></p>
                    <p id="highlight-text"></p>
                </div>
            ) : null}
            <div>
                <div id="error-message" className="col-md-12">
                    {showErrorMessage ? (
                        <h2>
                            Error: <span id="error-message-full">?</span>
                        </h2>
                    ) : null}
                </div>
            </div>
            <div>
                <div
                    id="api-response-text"
                    dangerouslySetInnerHTML={{ __html: apiResponse }}
                    className="col-md-12"
                >
                    {/* {apiResponse} */}
                </div>
            </div>

            <div
                className="row text-center"
                style={{ display: "flex", justifyContent: "center" }}
            >
                <div
                    style={{
                        width: "100%",
                        marginBottom: "0px",
                        marginTop: "-10px",
                        display: "none",
                        verticalAlign: "baseline",
                    }}
                    id="mic_listening"
                >
                    <img
                        style={{ height: "3rem", marginTop: "4px", marginBottom: "4px" }}
                        className="img-recorder"
                        src={micListeningGif}
                        alt="Mic Listening"
                    />
                    <span style={{ fontSize: "15px" }}>Recording now</span>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {showLoading ? (
                    <img
                        style={{ height: "5rem", justifyContent: "center" }}
                        className="loading-symbol"
                        src={Loader}
                        alt="loading"
                        id="load"
                    />
                ) : null}
            </div>

            {showTextHighlight ? (
                <div
                    id="textHighlight"
                    // class="row text-center justify-content-center"
                    className="row text-center justify-content-center"
                    // style="margin-top: 20px; display: none"
                    style={{ marginTop: "20px", display: "none" }}
                >
                    ?
                </div>
            ) : null}
            {/* <p className="alert-class" id="api-response-text" style={{color:'red', fontWeight:'600'}}></p> */}
            <div className="modal-buttons">

                {showStartButton && !showFinishButton && (
                    <div className="read_along_read_aloud_btns_container">
                        <button
                            id="playAudio"
                            className="play-button"
                            onClick={startReadAloud}
                            disabled={isReadAloud}
                        >
                            <GiSpeaker />
                        </button>
                        <button onClick={stopReadAloud} disabled={!isReadAloud}>
                            <GiSpeakerOff />
                        </button>
                        {isPaused ? (
                            <button onClick={resumeReading} disabled={!isReadAloud}>
                                <IoIosPlay />
                            </button>
                        ) : (
                            <button onClick={pauseReading} disabled={!isReadAloud}>
                                <IoIosPause />
                            </button>
                        )}
                        {stopRecButton ? (
                            <button
                                onClick={stopRecording}
                            >
                                <FaMicrophoneSlash />
                            </button>
                        )
                            :
                            <button>
                                <FaMicrophone style={{height:'22px'}} onClick={startRecording} />
                            </button>
                        }
                    </div>
                )}
                {showFinishButton && (
                    <button
                        className="record-button"
                        onClick={FinishStory}
                        id="recordButton"
                    >
                        Exit Story
                    </button>
                )}

                {showTryAgainButton && (
                    <button className="try-again-button" id="try-again-button">
                        Try Again
                    </button>
                )}

            </div>
        </div>
    );
};

export default StoryReadAloud;
