import { QuizApiCalls } from "services/quiz";
import { mutation } from "./mutations";

const handleError = (err) => {
  console.log("Error in student action : ", err);
  return err.response || err;
};

// Actions
export const getQuizList = () => async (dispatch) => {
  try {
    const result = await QuizApiCalls.getQuizList();
    console.log(result);
    dispatch(mutation.setQuizList(result.quizzes));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getAllQuiz = (params) => async (dispatch) => {
  try {
    const result = await QuizApiCalls.getAllQuiz(params);
    console.log("result", result);
    dispatch(mutation.setAllQuiz(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getQuizByCategory = (gradeId) => async (dispatch) => {
  console.log("categoryres");

  try {
    const result = await QuizApiCalls.getQuizByCategory(gradeId);
    console.log("categoryresult", result);
    dispatch(mutation.setQuizByCategory(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getAllLeadQuiz = (params) => async (dispatch) => {
  try {
    const result = await QuizApiCalls.getAllLeadQuiz(params);
    console.log("result", result);
    dispatch(mutation.setAllQuiz(result.data.quiz_list));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getQuizContent = (query) => async (dispatch) => {
  try {
    const result = await QuizApiCalls.getQuizContent(query);
    console.log("result", result);
    dispatch(mutation.setQuizContent(result.data));
    return result;
  } catch (err) {
    handleError(err);
  }
};
export const getLeadQuizContent = (query) => async (dispatch) => {
  try {
    const result = await QuizApiCalls.getLeadQuizContent(query);
    console.log("result", result);
    dispatch(mutation.setQuizContent(result.data));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getLeadQuiz = (query) => async (dispatch) => {
  try {
    const result = await QuizApiCalls.getLeadQuiz(query);

    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getById = (Id) => async (dispatch) => {
  try {
    const result = await QuizApiCalls.getById(Id);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const storeResult =
  (childID, rewardpoints, quizID) => async (dispatch) => {
    try {
      const payload = {
        child: childID,
        quiz: quizID,
        quiz_response: {},

        status: 1,
        reward_points: rewardpoints,
      };
      const result = await QuizApiCalls.storeQuizResult(payload);
      return result;
    } catch (err) {
      handleError(err);
    }
  };


  export const storeSinglePageResult =
  (childID, quizID, questionID , selectedOption , option , timeTaken,selectedBucket) => async (dispatch) => {
    try {
      const payload = {
        child_id: childID,
        quiz_id: quizID,
        question_id:questionID,
        selected_option:selectedOption,
        result:option,
        time_taken:timeTaken, 
        selected_bucket:selectedBucket
      };
      const result = await QuizApiCalls.storeSingleQuizResult(payload);
      return result;
    } catch (err) {
      handleError(err);
    }
  };



export const storeleadgenquizresult =
  (childID, rewardpoints, quizID, rightans, wrongans, totalscore) =>
  async (dispatch) => {
    try {
      const payload = {
        data: {
          quiz_response: {
            number_of_right_ans: rightans,
            number_of_wrong_ans: wrongans,
            total_score: totalscore,
          },
          temp_child_id: childID,
          status: 1,
          reward_points: rewardpoints,
          quiz_id: quizID,
        },
      };
      const result = await QuizApiCalls.storeLeadQuizResult(payload);
      return result;
    } catch (err) {
      handleError(err);
    }
  };
export const storeStudentInfo =
  (name, grade, email, mobilenumber) => async (dispatch) => {
    try {
      const payload = {
        data: {
          name: name,
          grade: grade,
          email: mobilenumber + "@gmail.com",
          mobile_number: "+91" + mobilenumber,
        },
      };
      const result = await QuizApiCalls.storeStudentInfo(payload);
      return result;
    } catch (err) {
      handleError(err);
    }
  };
export const quizStatus = (quizId, childId) => async (dispatch) => {
  try {
    const result = await QuizApiCalls.getQuizStatus(quizId, childId);
    console.log("result", result);

    return result;
  } catch (err) {
    handleError(err);
  }
};

export const createStreakRequest = (payload) => async (dispatch) => {
  try {
    const result = await QuizApiCalls.createStreak(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};
