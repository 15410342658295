import React from 'react';
import Header from "../../components/Header";
import Passage from '../SpeechTestPassage/Passage';
import CMFluencyLoader from '../../components/CMFluencyLoader';
import CongratulationModal from '../../components/CMCongratulationModal/index';
import { connect } from "react-redux";
import {  withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import * as cmfluencyAction from "../../store/cmfluency/actions";
import Navbar from "../../components/Navbar";
import { storage as LocalStorage } from "services/config/storage";
import '../SpeechTestPassage/SpeechTestPassage.scss';
import CEECTUploadForm from './CEECTUploadForm';

const CEECTSpeechTestPassage = (props) => {
  let isLogin = LocalStorage.fetch.authToken() ? true :false;
  let paramUrl = new URLSearchParams(props.location.search);
  let passage_id = paramUrl.get("passage_id");

    return (
      <div>
        {isLogin ? <Navbar hideForCMFluency = {true}/> : <Header ceectTest={true} />}
        <CongratulationModal ceectTest={true}/>
        <div className= {`passage-layout ${isLogin ? "on-login" : null}`}>
          <div className="passage-container">
            {
              props.cmfluency.cmfluencyRocketLoader ?
                <CMFluencyLoader ceectTest={true} /> :
                <>
                  <Passage passage_id={passage_id} />
                  <CEECTUploadForm passage_id={passage_id} />
                </>
            }
          </div>
        </div>
      </div>
    );
}
export default connect(
    ({ cmfluency ,user}) => ({
      cmfluency : {...cmfluency},
      user : {...user}
    }),
    dispatch => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(CEECTSpeechTestPassage));