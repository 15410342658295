import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Loader from "../components/Loader/Loader";
// import FLive from "../views/FLive";
// import QuizAuthenticate from "../views/QuizAuthenticate";
import ActiveUserPage from "../views/ActiveUserPage";
import StudentProfile from "views/Student/Profile/index.js";
import { persistStore } from "redux-persist";
import store from "../store";
import { PersistGate } from "redux-persist/integration/react";
import FormComplete from "views/Student/Profile/ProfileForm/FormComplete";
import Results from "../views/Results";
import HomeworksPopUp from "../views/ActiveUserPage/HomeworkPopUp";
import TestsPopUp from "../views/ActiveUserPage/TestsPopUp";
import Worksheets from "../views/Worksheets";
import CMFReport from "../views/CMFReport";
import ReadAlong from "../views/ReadAlong/ReadAlong";
import ReadAloud from "../views/ReadAloud/ReadAloud";
import StoryDetailsPage from "../views/StoryDetailsPage";
import { STORY_DATA } from "../views/ReadAlong/constants";
import ReadAloudDetailsPage from "../views/ReadAloudDetailsPage";
import TeacherDashboard from "../views/TeacherDashboard";
import TeacherSignup from "../views/TeacherSignup";
import StoryReadAloud from "../views/StoryDetailsPage/StoryReadAloud";
import CEECTSpeechTestInstructions from "../views/SpeechTestInstructions/CEECTSpeechTestInstructions";
import CEECTSpeechTestPassage from "../views/CEECTSpeechTestPassage";
import CEECTCMFReport from "../views/CEECTCMFReport";
const Onboarding = Loadable({
  loader: () => import("../views/Onboarding"),
  loading: Loader,
});
const SchoolCodeSignup = Loadable({
  loader: () => import("../views/SchoolCodeSignup"),
  loading: Loader,
});
// const Login = Loadable({
//   loader: () => import("../views/Login"),
//   loading: Loader,
// });
const Signup = Loadable({
  loader: () => import("../views/Signup"),
  loading: Loader,
});
const EuroLogin = Loadable({
  loader: () => import("../views/EuroLogin"),
  loading: Loader,
});
const Dashboard = Loadable({
  loader: () => import("../views/Dashboard"),
  loading: Loader,
});
const Pictionary = Loadable({
  loader: () => import("../views/Pictionary"),
  loading: Loader,
});
const Leaderboard = Loadable({
  loader: () => import("../views/Leaderboard"),
  loading: Loader,
});
const Pricing = Loadable({
  loader: () => import("../views/Pricing"),
  loading: Loader,
});
const Collections = Loadable({
  loader: () => import("../views/Collections"),
  loading: Loader,
});
const QuizSection = Loadable({
  loader: () => import("../views/QuizSection"),
  loading: Loader,
});
// const LeadQuizSection = Loadable({
//   loader: () => import("../views/LeadQuizSection"),
//   loading: Loader,
// });
const Activities = Loadable({
  loader: () => import("../views/Activities"),
  loading: Loader,
});
const MagicStory = Loadable({
  loader: () => import("../views/MagicStory"),
  loading: Loader,
});
const Temp = Loadable({
  loader: () => import("../views/MagicStory/bubbleAnimation2"),
  loading: Loader,
});
const NotFound = Loadable({
  loader: () => import("../components/NotFound"),
  loading: Loader,
});
// const magicStoryCard = Loadable({
//   loader: () => import("../components/magicStoryCard/magicStoryCard"),
//   loading: Loader,
// });

const SpeechTest = Loadable({
  loader: () => import("../views/SpeechTest"),
  loading: Loader,
});

const SpeechTestPassage = Loadable({
  loader: () => import("../views/SpeechTestPassage"),
  loading: Loader,
});

const SpeechTestInstructions = Loadable({
  loader: () => import("../views/SpeechTestInstructions"),
  loading: Loader,
});

const Assessment = Loadable({
  loader: () => import("../views/Assessment"),
  loading: Loader,
});

const Meet = Loadable({
  loader: () => import("../views/Meet"),
  loading: Loader,
});
const SchoolReport = Loadable({
  loader: () => import("../views/SchoolReport"),
  loading: Loader,
});
const CBSESchoolReport = Loadable({
  loader: () => import("../views/CbseSchoolReport"),
  loading: Loader,
});
const CBSEReadingLogin = Loadable({
  loader: () => import("../views/CBSEReadingLogin"),
  loading: Loader,
});
const SchoolNotFound = Loadable({
  loader: () => import("../views/SchoolNotFound"),
  loading: Loader,
});
const LookerStudio = Loadable({
  loader: () => import("../views/LookerStudio"),
  loading: Loader,
});
const AIRRReport = Loadable({
  loader: () => import("../views/AIRRReport"),
  loading: Loader,
});

const MeritList = Loadable({
  loader: () => import("../views/MeritList"),
  loading: Loader,
});

const FreadosMeritList = Loadable({
  loader: () => import("../views/FreadosMeritList"),
  loading: Loader,
});

const GuidedPath = Loadable({
  loader: () => import("../views/GuidedPath"),
  loading: Loader,
});

const News = Loadable({
  loader: () => import("../views/News"),
  loading: Loader,
});

const ReadingOlympiad = Loadable({
  loader: () => import("../views/ReadingOlympiad"),
  loading: Loader,
});

const Winners = Loadable({
  loader: () => import("../views/Winners"),
  loading: Loader,
});

const Assignments = Loadable({
  loader: () => import("../views/Assignments"),
  loading: Loader,
});

class RootRouter extends Component {
  constructor(props) {
    super(props);
    this.persistor = persistStore(store);
  }

  render() {
    return (
      <PersistGate loading={<div>Loading...</div>} persistor={this.persistor}>
        <Router>
          <Switch>
            {/* <Route exact path="/feed/:Id" component={Dashboard} /> */}
            <Route exact path="/user/homeworks" component={HomeworksPopUp} />
            <Route exact path="/user/tests" component={TestsPopUp} />
            <Route exact path="/user/results" component={Results} />

            <Route exact path="/feed/:Type/:Id" component={Dashboard} />
            <Route exact path="/story/:Id" component={Dashboard} />
            <Route exact path="/" component={Dashboard} />
            <Route
              exact
              path="/story"
              component={Dashboard}
              //key={"story-key"}
            />
            <Route path="/Pictionary" component={Pictionary} />
            <Route path="/leaderboard" component={Leaderboard} />
            <Route exact path="/quiz" component={QuizSection} />
            {/* <Route exact path="/quiz" component={QuizAuthenticate} /> */}
            {/* <Route exact path="/user/quiz" component={QuizSection} /> */}

            <Route exact path="/quiz/:Id" component={QuizSection} />
            {/*  <Route exact path="/freadom-quiz" component={LeadQuizSection} />
            <Route exact path="/freadom-quiz/:Id" component={LeadQuizSection} /> */}
            {/* <Route path="/flive" component={FLive} /> */}
            {/*<Route path="/user" component={ActiveUserPage} />*/}
            <Route path="/onboarding" component={Onboarding} />
            {/* <Route path="/login" component={Login} /> */}
            {/* <Route path="/sign-up" component={Signup} /> */}
            <Route path="/get-started" component={Signup} />
            <Route
              path="/read-along/readStory/:storyId"
              render={(props) => (
                <StoryReadAloud
                  {...props}
                  data={STORY_DATA[props.match.params.storyId]}
                />
              )}
            />
            {/* <Route
              path="/read-along/readStory/:storyId"
              render={(props) => (
                <StoryDetailsPage
                  {...props}
                  data={STORY_DATA[props.match.params.storyId]}
                />
              )}
            /> */}
            <Route path="/school-code-sign-up" component={SchoolCodeSignup} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/euro-login" component={EuroLogin} />
            <Route path="/collection/:id" component={Collections} />
            <Route exact path="/activity/:Id" component={Activities} />
            <Route path="/activities" component={Activities} />
            <Route path="/magicStory" component={MagicStory} />
            <Route path="/user/reports" exact component={Results} />
            <Route path="/user" component={ActiveUserPage} />
            <Route path="/temp" component={Temp} />
            <Route exact path="/worksheets/:Id" component={Worksheets} />
            <Route exact path="/cmfreport" component={CMFReport} />
            <Route exact path="/ceect/cmfreport" component={CEECTCMFReport} />

            <Route
              path="/student/profile/:edit"
              exact
              component={StudentProfile}
            />
            <Route path="/student/profile" exact component={StudentProfile} />
            <Route path="/profile-complete" exact component={FormComplete} />
            <Route path="/speech-test" exact component={SpeechTest} />
            <Route path="/school-report" exact component={SchoolReport} />
            <Route
              path="/cbse-school-report"
              exact
              component={CBSESchoolReport}
            />
            <Route path="/cbsereadingapp/" component={CBSEReadingLogin} />
            <Route path="/school-not-found" component={SchoolNotFound} />
            <Route path="/looker-studio" component={LookerStudio} />

            <Route path="/airr-report" exact component={AIRRReport} />
            <Route path="/meritlist" exact component={MeritList} />
            <Route path="/winners" exact component={Winners} />
            <Route
              path="/freados-merit-list"
              exact
              component={FreadosMeritList}
            />
            <Route path="/guided-path" exact component={GuidedPath} />
            <Route path="/news" exact component={News} />
            <Route path="/news/:id" exact component={News} />
            <Route
              path="/international-olympiad"
              exact
              component={ReadingOlympiad}
            />
            <Route
              path="/speech-test/instructions"
              exact
              component={SpeechTestInstructions}
            />
            <Route
              path="/speech-test/passage"
              exact
              component={SpeechTestPassage}
            />
            <Route
              path="/read-aloud/readStory/:storyId"
              exact
              component={ReadAloudDetailsPage}
            />
            <Route
              path="/assignment"
              exact
              component={Assignments}
            />
            <Route path="/teacher-signup" exact component={TeacherSignup} />
            <Route path="/read-along" exact component={ReadAlong} />
            <Route path="/read-aloud" exact component={ReadAloud} />
            <Route path="/teacher-dashboard" component={TeacherDashboard} />
            <Route path="/assessment" exact component={Assessment} />
            <Route path="/meeting/:room/:id" exact component={Meet} />
            <Route path="/ceect/speech-test/instructions" exact component={CEECTSpeechTestInstructions} />
            <Route path="/ceect/speech-test/passage" exact component={CEECTSpeechTestPassage} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </PersistGate>
    );
  }
}

export default RootRouter;
