import { Api } from "./config/request";

export const getCollectionsList = () => Api.getRequest("misc/v1/collection/");

export const getCollectionById = (collectionId, offset ,source = null) => {
  // console.log(offset);
  if(source){
    return Api.postRequest(
      `misc/v1/read-india/collection/`,{"offset":offset}
    );
  }else{
    return Api.getSearchRequest(
      `story/v1/?collection=${collectionId}&offset=${offset}`
    );
  }  
};

export const Collections = {
  getCollectionsList,
  getCollectionById,
};
