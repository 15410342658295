/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
// var FormData = require('form-data');
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as cmfluencyAction from "../../store/cmfluency/actions";
import * as testActions from "../../store/tests/actions";
import { Form, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import getBlobDuration from "get-blob-duration";
import "react-phone-input-2/lib/style.css";
import { storage as LocalStorage } from "services/config/storage";
import { cmFluencyTestFinalSubmissionAnalytics } from "../../utils/AnalyticsHelper";
import { SpreadSheetLogsAPIcalls } from "../../services/SpreadSheetLogs";
import "../SpeechTestPassage/SpeechTestPassage.scss";
import "../SpeechTestPassage/UploadForm.scss";
import Loader from "assets/image/loader2.gif";
// import Loader from "components/Loader";

const CEECTUploadForm = (props) => {
  let paramUrl = new URLSearchParams(props.location.search);
  let passageId = paramUrl.get("passageid");
  let testid = paramUrl.get("testid");
  let isLogin = LocalStorage.fetch.authToken() ? true : false;
  let defaultChild = LocalStorage.fetch.defaultChild();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  // console.log(loading);

  useEffect(() => {
    let parentData = LocalStorage.fetch.parentData();
    let parentPhone =
      parentData?.country_code.toString() + parentData?.contact_no.toString();
    if (defaultChild) {
      setFormData({
        ...formData,
        name: defaultChild?.name,
        email: parentData?.email,
        phone: parentPhone,
      });
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      await fetch(props.cmfluencyAudioUrl)
        .then((r) => r.blob())
        .catch((err) => {
          props.setAudioUrl("");
        });
    }
    fetchData();
  }, [props.cmfluencyAudioUrl]);

  const handleUpdateState = (e) => {
    e.preventDefault();
    if (e.target.name === "school_code") {
      const regex = /^[A-Za-z]*$/;
      if (regex.test(e.target.value)) {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCodeChange = (key, v) => {
    setFormData({ ...formData, code: v.dialCode, phone: key });
  };

  const validateAudio = async () => {
    if (props.cmfluencyAudioUrl) {
      const duration = await getBlobDuration(props.cmfluencyAudioUrl);
      if (duration < 11) {
        props.setAudioUrl(null);
        await props.setAudioError(
          "Audio speaking time is less than 11 seconds. Please provide a longer audio"
        );
        return false;
      }
      return true;
    } else {
      await props.setAudioUrl(null);
      return false;
    }
  };

  const validateName = (name) => {
    let n = name?.trim();
    setFormData({ ...formData, name: n });
    if (n?.length) {
      const re = /^[a-zA-Z0-9_ .]*$/;
      if (re.test(n)) {
        setFormData((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, name: "" },
        }));
        return true;
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            name: "Please enter a valid child name",
          },
        }));
        return false;
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        errors: { ...prevState.errors, name: "Please enter the child name" },
      }));
      return false;
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email?.trim().length) {
      if (re.test(email)) {
        setFormData((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, email: "" },
        }));
        return true;
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            email: "Please enter a valid email address",
          },
        }));
        return false;
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: "Please enter the email address",
        },
      }));
    }
  };

  const validatePhonenumber = (phone) => {
    var phoneno =
      /^\+?([0-9]{2})\)?[-]?([0-9]{3})[ ]?([0-9]{3})?[-]?([0-9]{4})$/;
    if (phone?.trim().length) {
      if (phone?.match(phoneno)) {
        setFormData((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, phone: "" },
        }));
        return true;
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            phone: "Please enter a valid phone number",
          },
        }));
        return false;
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          phone: "Please enter the phone number",
        },
      }));
    }
  };

  const validateSchoolCode = (school_code) => {
    if (isLogin || (school_code && school_code !== "")) {
      setFormData((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          school_code: "",
        },
      }));
      return true;
    } else {
      setFormData((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          school_code: "Please enter school code",
        },
      }));
    }
    return false;
  };

  function convertJSONToListOfObjects(jsonObj) {
    const objectList = [];

    for (const [key, value] of Object.entries(jsonObj)) {
      objectList.push({ key, value });
    }

    return objectList;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      (await validateAudio()) &&
      validateName(formData?.name) &&
      validateEmail(formData?.email) &&
      validateSchoolCode(formData?.school_code) &&
      validatePhonenumber(formData?.phone)
      // &&
      // validateSection(formData?.section)
    ) {
      const uuid = uuidv4();
      let fId = uuidv4();
      sessionStorage.setItem("freadom_id", JSON.stringify(fId));
      setLoading(true);

      let blobUrlToblob = await fetch(props.cmfluencyAudioUrl).then((r) =>
        r.blob()
      );
      var wavfromblob = new File([blobUrlToblob], "incomingaudioclip.wav");
      var audioFormdata = new FormData();

      audioFormdata.append("file", wavfromblob, "New Recording.wav");
      let audioUrl = await props.uploadAudio(audioFormdata);
      // setLoading(false);
      let formDataObj = {
        source: "CEECT",
        process_id: uuid,
        audio_link: audioUrl,
        passage: props.cmfluencyPassageId,
        grade: isLogin
          ? defaultChild?.grade?.name
          : props.grade
          ? props.grade
          : props.cmfluencyGrade
          ? props.cmfluencyGrade
          : "Grade 8",
        child_name: formData?.name,
        email: formData?.email,
        // section: formData?.section,
        mobile_number: formData?.phone,
        crm_lsq_id: isLogin ? props?.student?.crm_lsq_id : null,
        freadom_id: defaultChild?.id ? defaultChild?.id : fId,
        school_code: isLogin
          ? defaultChild?.school_code
          : formData?.school_code,
      };
      cmFluencyTestFinalSubmissionAnalytics(props.cmFluencyViewID, formDataObj);
      let cmFluencyUploadResponse = await props.sendCMFluencyData(
        formDataObj,
        isLogin
      );

      if (cmFluencyUploadResponse) {
        setLoading(false);
        if (
          cmFluencyUploadResponse?.data?.status !== "Error" &&
          !(
            cmFluencyUploadResponse?.data?.speech_rate >= 2.5 ||
            cmFluencyUploadResponse?.data?.speech_rate < 1.9
          )
        ) {
          await props.setRocketLoader(true);
          setTimeout(() => {
            props.setRocketLoader(false);
            props.handleCMCongratulationModal(true);
          }, 2500);
        }
      }
      try {
        let new_payload = convertJSONToListOfObjects(formDataObj);
        new_payload = JSON.stringify(new_payload);
        let logsRes = await SpreadSheetLogsAPIcalls.speechTestLogs(new_payload);
      } catch (error) {
        console.error("google spreadsheet API Error:", error);
      }
      if (cmFluencyUploadResponse?.status) {
        let updateReportObj = {
          data: [
            {
              test_id: testid,
              status: "completed",
              result_link: cmFluencyUploadResponse?.report_1_drive_link,
            },
          ],
        };
        if (isLogin && testid) props.updateTestReport(updateReportObj);
      }
    }
  };

  return (
    <div className="cm_form_block">
      <Form layout="vertical" className="cm_form" onSubmit={handleSubmit}>
        <div className="cm_para1_title">
          Enter your details to generate your test report
        </div>
        <Form.Item style={{ width: 300 }} className={"input-block"}>
          <Input
            size="large"
            name="name"
            value={formData?.name}
            placeholder="Your name"
            onChange={(e) => handleUpdateState(e)}
          />
          <span style={{ color: "red", fontSize: 14 }}>
            {formData?.errors?.name}
          </span>
        </Form.Item>
        <Form.Item style={{ width: 300 }} className={"input-block"}>
          <Input
            size="large"
            name="email"
            value={formData?.email}
            placeholder="Your email Id"
            onChange={(e) => handleUpdateState(e)}
          />
          <span style={{ color: "red", fontSize: 14 }}>
            {formData?.errors?.email}
          </span>
        </Form.Item>

        <Form.Item style={{ width: 300 }} className={"input-block"}>
          <Input
            size="large"
            name="school_code"
            maxLength={6}
            value={formData?.school_code}
            placeholder="School Code"
            onChange={(e) => handleUpdateState(e)}
            pattern="[A-Za-z]+"
            title="Only letters are allowed"
          />
          <span style={{ color: "red", fontSize: 14 }}>
            {formData?.errors?.school_code}
          </span>
        </Form.Item>

        <Form.Item className={"input-block"}>
          <PhoneInput
            placeholder="Your phone number"
            value={formData?.phone}
            autoFormat={true}
            country={"in"}
            inputClass="input-class width-100"
            countryCodeEditable={false}
            buttonClass="flag-class"
            onChange={(e, v) => handleCodeChange(e, v)}
            // onChange={phone => console.log("phone====",phone)}
          />
          <span style={{ color: "red", fontSize: 14 }}>
            {formData?.errors?.phone}
          </span>
        </Form.Item>
        {loading ? (
          <div className="loading_container">
            <img
              style={{
                height: "18rem",
                width: "18rem",
                justifyContent: "center",
              }}
              // className="loading-symbol"
              src={Loader}
              alt="loading"
              id="load"
            />
            {/* <Loader/> */}
          </div>
        ) : (
          <button
            className="cm_section_button"
            // type="primary"
            // disabled={hasErrors(getFieldsError())}
            htmltype="submit"
            // disabled={this.state.disabledChildButton}
            // loading={this.state.isSendingOtp}
          >
            Submit the test
          </button>
        )}
      </Form>
    </div>
  );
};
export default connect(
  ({ cmfluency, student, user }) => ({
    ...cmfluency,
    student: { ...student },
    user: { ...user },
  }),
  (dispatch) =>
    bindActionCreators({ ...cmfluencyAction, ...testActions }, dispatch)
)(withRouter(CEECTUploadForm));
