import React from 'react';
import Header from "../../components/Header";
import Content from './Content';
import { connect } from "react-redux";
import {  withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { storage as LocalStorage } from "services/config/storage";
import Navbar from "../../components/Navbar";
import * as cmfluencyAction from "../../store/cmfluency/actions";

const CEECTSpeechTestInstructions = (props) => {
    let isLogin = LocalStorage.fetch.authToken() ? true :false;

    return (
        <div>
            {isLogin ? <Navbar hideForCMFluency = {true}/> : <Header ceectTest={true}/>}
            <Content ceectTest={true}/>
        </div>
    )
}
export default connect(
    ({ cmfluency }) => ({
      ...cmfluency
    }),
    dispatch => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(CEECTSpeechTestInstructions));