import React from "react";
import "./FluencyBanner.scss";
import { Button } from "antd";

const FluencyBanner = props => {
  const { data } = props;
  const bannerBackground = data ? `url(${ data })` : 'linear-gradient(to right, rgb(62, 34, 114), rgba(32, 115, 182, 0.83),rgba(92, 189, 221, 0.33))'
  return (
    <div
      style={{
        ...props.style
      }}
      className="fluency-banner banner-image"
    >
      <div style={{
          padding: '58px 40px'
        }}
        className="text-container">
        
        {/* <div className="name">Check your fluency score!</div>
        <div>&nbsp;</div>
        <div className="description">Get a personalised report to check your child's accuracy of pronunciation, pitch/tone and speed</div> */}
        {/* <Button type="primary"
              className="primary-btn banner-btn"
              size="large">
          Take the challenge
        </Button> */}
      </div>
    </div>
  );
};

export default FluencyBanner;
