import React, { useEffect, useState, useLayoutEffect } from "react";
import Navbar from "components/Navbar";
// import BannerFront from "../../assets/Report/Capture.png";
import ReportImage from "../../assets/Report/Group.png";
import Table from "react-bootstrap/Table";
import Icon from "../../assets/Report/icon.png";

// import BannerLast from "../../assets/Report/Capture123.png"
import Logo from "../../assets/Report/Logo.png";
import Side from "../../assets/Report/Side.png";
import Side1 from "../../assets/Report/Side1.png";
import Upper from "../../assets/Report/UpperCEECT.png";
import UpperMobile from "../../assets/Report/bg.jpg";

import * as cmfluencyAction from "../../store/cmfluency/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { storage as LocalStorage } from "services/config/storage";
import Loader from "components/Loader";
import "./index.scss";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import Bannerlast1 from "../../assets/Report/Bannerlast1.png";
import Bannerlastmob from "../../assets/Report/Bannerlastmob.png";
import { Button } from "antd";

import { FaBook } from "react-icons/fa";
import { TiSortAlphabetically } from "react-icons/ti";
import { MdMusicNote } from "react-icons/md";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Header from "../../components/Header";

const CEECTCMFReport = (props) => {
  const [deviceType] = useDeviceDetect();
  const [data, setData] = useState(" ");
  const [isLoading, setLoading] = useState(false);

  const childList = LocalStorage.fetch.defaultChild();
  let freadomId = JSON.parse(sessionStorage.getItem("freadom_id"));

  useLayoutEffect(() => {
    async function fetchMyAPI() {
      let response = null;
      if (childList?.id) {
        response = await props.getReportData(childList.id);
      } else {
        response = await props.getReportData(freadomId);
      }
      setData(response.data[response.data.length - 1]);
      console.log("data:", data);
    }
    fetchMyAPI();
  }, []);

  useEffect(() => {
    return () => {
      props.clearReportData();
    };
  }, []);

  const savePDF = () => {
    setLoading(true);
    // try{
    const header = document.getElementById("report-header"); // Select the header
    const originalStyle = header.style.position; // Save the original position
    const btnContainer = document.getElementById("ceect-btn-container");
    // Temporarily remove fixed positioning
    header.style.position = "static";
    btnContainer.style.display = "none";

    const input = document.getElementById("download-report");
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
      var pdfWidth = pdf.internal.pageSize.getWidth();
      var pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("Report.pdf");

      header.style.position = originalStyle;
      btnContainer.style.display = "flex";
    });
    // }catch(err){
    //   console.log(err,'Error generating pdf...')
    // }finally{
    setLoading(false);
    // }
  };

  return (
    <div id="download-report">
      {Object.keys(props.reportData).length < 1 ? (
        <Loader />
      ) : (
        <div className="ceect-cmf-report-container">
          <Header ceectTest={true} />
          <div className="ceect-upper">
            <div className="ceect-bannerImageContainer">
              <img
                className="bannerImage"
                src={deviceType === "mobile" ? UpperMobile : Upper}
              />
            </div>
            <div className="ceect-uppertext" style={{ paddingTop: "5%" }}>
              <div style={{ display: "flex" }}>
                <h5>Date of Assessment &nbsp;:</h5>
                <h5 className="heading1">{data?.submitted}</h5>
              </div>
              <div style={{ display: "flex" }}>
                <h5>Name :</h5>
                <h5 className="heading1">{data.child_name}</h5>
              </div>
            </div>
          </div>
          <hr className="ceect-hrline1"></hr>
          {/* <div> */}
          <div className="ceect-row">
            <div className="ceect-col-2">
              <img className="side" src={Side1} />
            </div>
            <div className="ceect-col-9">
              <div className="ceect-report-para">
                Freadom Fluency Assessment is a reliable English fluency test
                that provides an accurate method for assessing language
                proficiency. This comprehensive periodic assessment is
                meticulously designed for the trainees of CEECT. It assesses
                their proficiency in key language skills, including words
                correct per minute, pronunciation accuracy, speech rate and
                comprehension.
              </div>
              <hr className="ceect-hrline"></hr>
              <div className="ceect-report-para">
                <p>
                  You read the passage <strong>{data.passage_name}</strong> in{" "}
                  <strong>{data.audio_len}</strong>
                </p>
                <p>
                  Your Reading Accuracy (words read correctly per minute) is{" "}
                  <strong>{data.wcpm}</strong> which is {data.wcpm_text} cohort
                  average of <strong>{data.wcpm_avg}</strong>
                </p>
              </div>
              <div>
                <img className="ceect-reportImage" src={ReportImage} />
                <div className="ceect-tablecontainer">
                  <Table
                    bordered
                    hover
                    style={{
                      borderRadius: "5px",
                      overflow: "hidden",
                      backgroundColor: "whitesmoke",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "45%" }}>METRICS</th>
                        <th style={{ width: "29%" }}>SCORE</th>
                        <th>SCHOOL AVERAGE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tableData">
                          <span className="tableHeading">
                            Pronunciation Accuracy
                          </span>
                          <br />{" "}
                          <span className="tableSubHeading">
                            Quality of pronunciation of each sound and word{" "}
                          </span>
                        </td>
                        <td>
                          <span className="data1">
                            {data.score ? data.score : "-"}
                          </span>
                        </td>
                        <td colSpan={2}>
                          <span className="data1">
                            {data.pronunciation_avg
                              ? data.pronunciation_avg
                              : "-"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="tableData">
                          <span className="tableHeading">Speech Rate</span>
                          <br />{" "}
                          <span className="tableSubHeading">
                            {" "}
                            Reading with pace, accuracy and expression{" "}
                          </span>{" "}
                        </td>
                        <td>
                          <span className="data1">
                            {data.speech_rate ? data.speech_rate : "-"}
                          </span>
                        </td>
                        <td colSpan={2}>
                          <span className="data1">
                            {data.speech_rate_avg ? data.speech_rate_avg : "-"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="tableData">
                          <span className="tableHeading">Reading pace</span>
                          <br />{" "}
                          <span className="tableSubHeading">
                            {" "}
                            Speed of reading{" "}
                          </span>
                        </td>
                        <td>
                          <span className="data1">
                            {data.reading_pace ? data.reading_pace : "-"}
                          </span>
                        </td>
                        <td colSpan={2}>-</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div>
                <div className="ceect-what-mean">
                  <img
                    style={{ marginRight: "1%", float: "left" }}
                    src={Icon}
                  />
                  <h5 style={{ marginTop: "0.3%" }}>What does this Mean</h5>
                </div>
                <div
                  style={{
                    margin: "2% 0",
                  }}
                  className="ceect-report-para"
                >
                  <p>
                    Your pronunciation accuracy is{" "}
                    <strong>{data.pronunciation_text} cohort average</strong>{" "}
                  </p>
                  <p>
                    Your speech rate is{" "}
                    <strong>{data.speech_rate_text} cohort average</strong>
                  </p>
                  <p>
                    Your reading pace is <strong>{data.reading_pace}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="ceect-col-2">
              <img className="side11" src={Side} />
            </div>
          </div>
          <div className="ceect-bannercontainer">
            <p className="ceect-paracontain">
              Recommendations&nbsp;for&nbsp;you&nbsp;to&nbsp;become&nbsp;the&nbsp;absolute&nbsp;best
            </p>
            <div className="ceect-paracontainer">
              <div className="ceect-paraouter">
                <div className="ceect-each-point-container">
                  <div className="ceect-icon-container">
                    <FaBook className="icon1" />
                  </div>
                  <p className="ceect-paratext">
                    <strong>Speech rate : </strong>
                    Reading consistently any book/article of your choice once a
                    day helps. More practice will reduce the pauses between
                    reading and improve reading fluency. Also, practice talking
                    about simple topics for a minute that draw from every
                    experience to enhance speaking skills and communicative
                    confidence.
                  </p>
                </div>
                <div className="ceect-each-point-container">
                  <div className="ceect-icon-container">
                    <TiSortAlphabetically className="icon1" />
                  </div>
                  <p className="ceect-paratext">
                    <strong>Words read correctly per minute : </strong>
                    Set a simple reading goal for yourself, a target number of
                    reading minutes for reading aloud each week. Start with
                    simple and short articles or stories.
                  </p>
                </div>
              </div>
              <div className="ceect-paraouter">
                <div className="ceect-each-point-container">
                  <div className="ceect-icon-container">
                    <MdMusicNote className="icon1" />
                  </div>
                  <p className="ceect-paratext">
                    <strong>Pronunciation score : </strong>
                    You can listen to podcasts, audiobooks or watch TED talks on
                    YouTube to understand and practice the pronunciation of
                    difficult and unknown words.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ceect-bannerImage1Container" id="ceect-btn-container">
            <Button
              className="ceect-save-report-btn"
              onClick={() => {
                savePDF();
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              Save Report Summary
            </Button>
          </div>
          {/* </div> */}
          <div className="ceect-footer-container">
            <img className="footer-logo" src={Logo} />
            <div className="footertext">
              <p className="footerpara">
                © 2025, Stones2Milestones. All rights reserved.
                <br />
                <span className="footerpara1">Reach us: go@getfreadom.com</span>
              </p>
              <p className="footerpara1"></p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  ({ cmfluency, user }) => ({
    ...cmfluency,
    user: { ...user },
  }),
  (dispatch) => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(CEECTCMFReport));
