const setQuizList = (data) => ({
  type: "SET_QUIZ_LIST",
  data,
});
const setAllQuiz = (data) => ({
  type: "SET_ALL_QUIZ",
  data,
});
const setQuizContent = (data) => ({
  type: "SET_QUIZ_CONTENT",
  data,
});
const setQuizByCategory = (data) => ({
  type: "SET_QUIZ_BY_CATEGORY",
  data,
});

export const mutation = {
  setQuizList,
  setAllQuiz,
  setQuizContent,
  setQuizByCategory
};
