import React from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { storage as LocalStorage } from "services/config/storage";
import * as cmfluencyAction from "../../store/cmfluency/actions";
import { cmFluencyTestViewReportAnalytics } from "../../utils/AnalyticsHelper";

import FluencyBanner from "./FluencyBanner";

import "./CMCongratulationModal.scss";
import "antd/dist/antd.css";

const CongratulationModal = (props) => {
  let isLogin = LocalStorage.fetch.authToken() ? true : false;
  let paramUrl = new URLSearchParams(props.location.search);
  let passageId = paramUrl.get("passageid");

  const handleCancel = () => {
    props.handleCMCongratulationModal(false);
    if (passageId || props.rcScore) {
      props.setRcScore("");
      props.history.push("/assessment");
    } else if (props.ceectTest) {
      props.history.push("/onboarding");
    } else {
      props.history.push("/activities");
    }
  };

  const handleViewResult = () => {
    cmFluencyTestViewReportAnalytics(props.cmFluencyViewID);
    // window.open(props.cmfluencyResult?.report_1_drive_link.split("&")[0]);
    if (isLogin && !props.ceectTest) {
      props.history.push("/cmfreport");
    } else if (props.ceectTest) {
      props.history.push("/ceect/cmfreport");
    }
    props.handleCMCongratulationModal(false);
  };

  return (
    <Modal
      className="congratulation-modals"
      centered
      visible={props.isCongratulationModal}
      onCancel={handleCancel}
      maskClosable={false}
      closable={true}
      footer={[]}
    >
      <div className="congratulation-banner-container">
        <FluencyBanner />
      </div>
      <div>
        {" "}
        <span className="congratulation-text">CONGRATULATIONS</span>
      </div>
      {!props.rcScore && (
        <div>
          {" "}
          {isLogin ? (
            <span className="generated-text">
              Your result has been generated!
            </span>
          ) : (
            <span className="generated-text">
              Your {props.ceectTest ? "Fluency Assessment Test": "speech test"} has been successfully submitted.
            </span>
          )}
        </div>
      )}
      {props.rcScore ? (
        <div className="rc-score-block">
          <span className="rc-score-tag">
            You scored <span className="rc-score">{props.rcScore}/10 </span> in
            the test!
          </span>
        </div>
      ) : (
        (isLogin || props.ceectTest) && (
          <Button
            className="congratulation-button"
            type="primary"
            onClick={() => {
              handleViewResult();
            }}
          >
            View Report
          </Button>
        )
      )}
    </Modal>
  );
};

export default connect(
  ({ cmfluency }) => ({
    ...cmfluency,
  }),
  (dispatch) => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(CongratulationModal));
